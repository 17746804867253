@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.review {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: var(--spacing-medium);
}

.subcard {
  padding: var(--spacing-medium);
  background-color: var(--color-squanchy-gray-20);
}

.subtitle {
  margin: 0;
  font-weight: 600;
}

.info,
.item,
.total {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: var(--spacing-tiny);

  & p {
    margin: 0;
  }

  & p:last-child {
    font-weight: 600;
  }
}

.item,
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--spacing-medium) 0;
}

.total {
  font-weight: 600;

  & p:last-child {
    font-size: 20px;
  }
}
