@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.title {
  align-items: center;
  color: var(--color-black);
  display: flex;
  line-height: 100%;
  padding: var(--spacing-small);
}

.title > h2 {
  color: var(--color-birdperson-gray-700);
  flex: 1;
  font-weight: bold;
  margin: 0;
  font-size: 18px;
}

.content {
  color: var(--color-birdperson-gray-700);
  font-size: 16px;
  font-weight: 500;
}

.details {
  font-size: 14px;
}

.confirmation {
  color: var(--color-birdperson-gray-700);
  font-size: 16px;
  font-weight: 500;
}
