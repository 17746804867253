@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.formGrid {
  display: grid;
  gap: var(--spacing-medium);
  grid-template-columns: repeat(auto-fill, 200px);
  max-width: 700px;
}

.title {
  margin-top: 0;
  margin-bottom: 40px;
}

.subtitle {
  margin: 40px 0;
}

.checkboxShipping {
  margin: var(--spacing-medium) 0;
}

.uppercase input {
  text-transform: uppercase;
}
