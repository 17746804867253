@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-top: var(--spacing-large);
}
