@import "former-kit-skin-pagarme/dist/styles/typography.css";

.exportPopover {
  font-size: var(--caption-font-size);

  & strong {
    text-align: center;
    white-space: nowrap;
  }
}
