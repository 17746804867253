@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.accountInfo > div {
  margin-bottom: var(--spacing-small);
}

.emptyStateBlock {
  margin-top: var(--spacing-large);

  & div {
    text-align: center;
    margin: var(--spacing-tiny) 0;
  }
}
