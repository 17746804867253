@import "former-kit-skin-pagarme/dist/styles/colors.css";

.infoCard {
  flex-wrap: nowrap;
}

.title {
  color: var(--color-birdperson-gray-700);
  font-size: 16px;
  margin: 0;
}

.description {
  font-size: 14px;
  margin: 0;

  & > * {
    margin-top: 6px;
    margin-bottom: 0;
  }
}

.link,
.link:hover {
  text-decoration: none;
}
