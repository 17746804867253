@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.centralizedItem {
  display: flex;
  align-items: center;
  justify-content: center;

  & .helpIcon {
    margin-left: 8px;
  }
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;

  & svg {
    width: 15px;
    margin-left: 8px;
  }
}

.link--active {
  color: var(--color-barney-purple-100);

  &:hover {
    cursor: pointer;
  }
}
