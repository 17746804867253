@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.property {
  display: block;
  max-width: 100%;
}

.title {
  color: var(--color-squanchy-gray-400);
  font-weight: normal;
  margin: 0 0 4px;
  font-size: 12px;
}

.value {
  color: var(--color-squanchy-gray-400);
  font-size: 14px;
  display: block;

  &:empty {
    border: 1px solid var(--color-squanchy-gray-100);
    margin: calc(var(--spacing-small) - 5px) 0;
  }
}

@media print {

  .value {
    font-size: 14px;
  }

  .value:empty {
    border: 0;
    margin: 0;

    &:after {
      content: "-";
    }
  }

  .property {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 16px;
    font-weight: bold;

    &:after {
      content: ": ";
      padding-right: 3px;
    }
  }
}
