@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.content {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
}

.item {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-left: var(--spacing-medium);
  }

  & > span {
    color: var(--color-squanchy-gray-300);
    font-size: var(--body-font-size);
    margin-bottom: var(--spacing-tiny);

    &:first-letter {
      text-transform: capitalize;
    }
  }

  & > div {
    align-items: center;
    display: flex;
    height: 30px;
  }
}

.identifier {
  color: var(--color-birdperson-gray-500);
  font-size: var(--body-font-size);
  font-weight: bold;
  line-height: var(--spacing-medium);
  margin: 0;
  max-width: 300px;
}

.propertyChildren {
  color: var(--color-birdperson-gray-500);
  font-size: var(--body-font-size);
  font-weight: bold;
  line-height: var(--spacing-medium);

  &:empty {
    border-top: 1px solid var(--color-squanchy-gray-100);
    margin-top: var(--spacing-small);
  }
}

.actions {
  align-items: center;
  display: flex;

  & > button:not(:first-of-type) {
    margin-left: var(--spacing-medium);
  }
}

@media print {

  .content {
    align-items: baseline;
    border-bottom: 1px solid var(--color-squanchy-gray-50);
    justify-content: center;
    padding: var(--spacing-small) 0;
    width: 100%;

    & > div:first-child {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .actions {
    display: none;
  }

  .item {
    height: 40px;

    & .identifier {
      align-items: baseline;
      font-size: 24px;
      height: auto;
      justify-content: center;
    }

    & > span {
      color: var(--color-black);
      flex-wrap: wrap;
      font-size: var(--body-font-size);
      font-weight: normal;
      text-align: center;
      max-width: 150px;
    }

    & > .propertyChildren {
      font-size: 14px;
      font-weight: bold;
      height: auto;
      justify-content: center;

      & abbr {
        background: none;
        font-size: 14px;
        padding: 0;
        text-transform: initial;
      }

      & strong {
        font-size: 14px;
      }
    }
  }
}
