@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.centralizedItem {
  margin: 0 auto;
  text-align: center;
}

.marginBottom {
  margin-bottom: var(--spacing-medium);
}

.marginRight {
  margin-right: var(--spacing-medium);
}

.marginTop {
  margin-top: var(--spacing-medium);
}
