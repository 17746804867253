@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--spacing-medium);
  max-width: 700px;
}

.title {
  margin-top: 0;
}
