@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.propertyCustom {
  padding-bottom: var(--spacing-small);
}

.itemContainer {
  background-color: #f3f3f3;
  padding: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
}

.content {
  width: 100%;
}

.title {
  font-size: var(--body-font-size);
  font-weight: bold;
  margin: 0 0 var(--spacing-medium);
}
